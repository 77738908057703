/* @import 'core/text-sizing'; */
@import 'core/gravity-forms';
@import 'core/spacing';

body {
  padding-top: 30px;
}

#mc-embedded-subscribe-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: .5em;
}

#mc-embedded-subscribe {
  background: black;
  border-width: 0;
  color: white;
  font-family: 'articulat-cf', 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
  font-style: italic;
  padding: .5em 3em;
  text-transform: uppercase;

  &:hover {
    color: #2CEF63;
  }
}

#mce-EMAIL {
  border-width: 0;
  padding: .5em 1em;
  width: min(300px, 90vw);
}

@media screen and (min-width: 768px) {
  body {
    padding-top: 60px;
  }
  #mc-embedded-subscribe-form {
    flex-direction: row;
    gap: 0;
    justify-content: center;
  }
}