/* @import 'core/text-sizing'; */
.gform_wrapper {
  font-family: inherit;
  font-size: 16px;
  color: #333333;
}

.gform_body {
  padding: 10px;
}

.gform_fields .gfield {
  margin-bottom: 10px;
}

.gfield_label {
  font-weight: bold;
  margin-bottom: 5px;
}

.ginput_container input[type=text],
.ginput_container input[type=email],
.ginput_container input[type=url],
.ginput_container input[type=tel],
.ginput_container input[type=number],
.ginput_container input[type=password],
.ginput_container textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 16px;
  font-family: inherit;
  color: #333333;
  background-color: #ffffff;
  transition: border-color 0.3s ease;
}
.ginput_container input[type=text]:focus,
.ginput_container input[type=email]:focus,
.ginput_container input[type=url]:focus,
.ginput_container input[type=tel]:focus,
.ginput_container input[type=number]:focus,
.ginput_container input[type=password]:focus,
.ginput_container textarea:focus {
  border-color: #0073aa;
  outline: none;
}

.gform_footer .gform_button {
  background-color: #0073aa;
  color: #ffffff;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-family: inherit;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.gform_footer .gform_button:hover {
  background-color: #005177;
}

.gform_confirmation_message {
  padding: 10px;
  background-color: #e0f7fa;
  border: 1px solid #b2ebf2;
  border-radius: 4px;
  color: #333333;
}

h1 {
  margin-block-start: 0;
  margin-block-end: 0.5em;
}

h2 {
  margin-block-start: 1em;
  margin-block-end: 0.5em;
}

h3 {
  margin-block-start: 1em;
  margin-block-end: 0.5em;
}

h4 {
  margin-block-start: 1em;
  margin-block-end: 0.5em;
}

h5 {
  margin-block-start: 1em;
  margin-block-end: 0.5em;
}

h6 {
  margin-block-start: 1em;
  margin-block-end: 0.5em;
}

body {
  padding-top: 30px;
}

#mc-embedded-subscribe-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

#mc-embedded-subscribe {
  background: black;
  border-width: 0;
  color: white;
  font-family: "articulat-cf", "Helvetica", "Arial", sans-serif;
  font-weight: bold;
  font-style: italic;
  padding: 0.5em 3em;
  text-transform: uppercase;
}
#mc-embedded-subscribe:hover {
  color: #2CEF63;
}

#mce-EMAIL {
  border-width: 0;
  padding: 0.5em 1em;
  width: min(300px, 90vw);
}

@media screen and (min-width: 768px) {
  body {
    padding-top: 60px;
  }
  #mc-embedded-subscribe-form {
    flex-direction: row;
    gap: 0;
    justify-content: center;
  }
}