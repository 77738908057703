// Define color variables
$gf-primary-color: #0073aa;
$gf-secondary-color: #005177;
$gf-background-color: #f7f7f7;
$gf-border-color: #cccccc;
$gf-text-color: #333333;

// Define typography variables
$gf-font-family: inherit;
$gf-font-size: 16px;
$gf-font-weight: normal;

// Define spacing variables
$gf-padding: 10px;
$gf-margin: 10px;

// Define button variables
$gf-button-background-color: $gf-primary-color;
$gf-button-text-color: #ffffff;
$gf-button-border-radius: 4px;
$gf-button-padding: 12px 20px;

// Gravity Forms styles
.gform_wrapper {
  font-family: $gf-font-family;
  font-size: $gf-font-size;
  color: $gf-text-color;
}

.gform_body {
  padding: $gf-padding;
}

.gform_fields .gfield {
  margin-bottom: $gf-margin;
}

.gfield_label {
  font-weight: bold;
  margin-bottom: 5px;
}

.ginput_container input[type="text"],
.ginput_container input[type="email"],
.ginput_container input[type="url"],
.ginput_container input[type="tel"],
.ginput_container input[type="number"],
.ginput_container input[type="password"],
.ginput_container textarea {
  width: 100%;
  padding: $gf-padding;
  border: 1px solid $gf-border-color;
  border-radius: 4px;
  font-size: $gf-font-size;
  font-family: $gf-font-family;
  color: $gf-text-color;
  background-color: #ffffff;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: $gf-primary-color;
    outline: none;
  }
}

.gform_footer .gform_button {
  background-color: $gf-button-background-color;
  color: $gf-button-text-color;
  padding: $gf-button-padding;
  border: none;
  border-radius: $gf-button-border-radius;
  font-size: $gf-font-size;
  font-family: $gf-font-family;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $gf-secondary-color;
  }
}

.gform_confirmation_message {
  padding: $gf-padding;
  background-color: #e0f7fa;
  border: 1px solid #b2ebf2;
  border-radius: 4px;
  color: $gf-text-color;
}

