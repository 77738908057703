// Define common margin values in ems
$common-margin-start: 1em;
$common-margin-end: 0.5em;

// Apply margin-block styles to headings
h1 {
  margin-block-start: 0; // Specific to h1
  margin-block-end: $common-margin-end;
}

h2 {
  margin-block-start: $common-margin-start;
  margin-block-end: $common-margin-end;
}

h3 {
  margin-block-start: $common-margin-start;
  margin-block-end: $common-margin-end;
}

h4 {
  margin-block-start: $common-margin-start;
  margin-block-end: $common-margin-end;
}

h5 {
  margin-block-start: $common-margin-start;
  margin-block-end: $common-margin-end;
}

h6 {
  margin-block-start: $common-margin-start;
  margin-block-end: $common-margin-end;
}
